<template>
  <div class="bg-image padding p0">
    <div class="level">
      <img
        class="level-left"
        src="@/assets/img/home/logo.png"
        @click="$router.go({ name: 'Home' })"
      />
      <div class="level-right is-size-3 mr-6">Zaloguj się</div>
    </div>
    <hr />
    <section class="mb-3">
      <login-form
        title="Wirtualny garaż - logowanie"
        icon="client.png"
        :registrationLink="{ name: 'ClientRegistration' }"
      ></login-form>
    </section>
    <section class="ftr">
      <page-footer></page-footer>
    </section>
  </div>
</template>
<script>
import LoginForm from "@/components/auth/LoginForm";
import Footer from "@/components/common/Footer";

export default {
  components: {
    "login-form": LoginForm,
    "page-footer": Footer,
  },
};
</script>
<style lang="scss">
.page-subtitle {
  position: relative;
  right: 2rem;
  color: white;
  font-weight: 700;
  font-size: 24pt;
}

.ftr {
  margin-top: 15rem;
  width: 100%;
  bottom: 0px;
}
.bg-image {
  background-image: url("./../assets/img/background/background1.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: #999;
  color: $primary;
  padding: 0;
  padding-top: 1rem;
  min-height: 50vh;

  a:hover {
    color: white;
  }

  hr {
    width: 90vw;
    height: 0.5rem;
    position: relative;
    left: 5vw;
  }
  .content {
    width: 30vw;
    min-height: fit-content;
    background-color: #ffffffc9;
    border-radius: 15px;
  }

  .header {
    position: relative;
    color: $primary;
    font-weight: 400;
    right: 1rem;
  }

  .container {
    .columns {
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }
}
</style>